.dropdown-menu .divider {
  margin: 0;
}

/* fix checkboxes position in ibox headers */
.ibox-title .i-checks {
  margin-top: -3px;
}

#myModal {
  z-index: 15000 !important;
}

.select2-container--open, .dropdown-menu {
  z-index: 15001 !important;
}

.file-name-cell {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-action-cell {
  width: 1px;
}


.top-navigation .nav > li a:hover, .top-navigation .nav > li a:focus {
  background: transparent;
  color: $navy;
}

.top-navigation .nav > li.active {
  background: transparent;
  border: none;
}